<template>
    <div class="BigBox">
      <!-- 顶部 -->
      <div class="Nanber">
          <!-- 古镇名称+更多 -->
          <div class="More">
              <!-- 古镇名称 -->
              <div class="PlaceName">
                  <div>XX古镇</div>
              </div>
              <!-- 更多 -->
              <div class="EvenMore">
                  <div>更多</div>
              </div>
          </div>
      </div>
      <!-- 内容 -->
      <div class="ContentsBox">
          <!-- 面包屑 -->
          <div class="Crumbs">
              <div>三维古镇</div>
              <img src="../../assets/QingXie/RightJianTou.png"/>
              <div>三维视频</div>
          </div>
          <!-- 视频介绍盒子 -->
          <div class="VideoConBox">
              <div class="VideoBox">
                  <div class="div">
                      <!-- <video src="../../assets/HomePage/YinDaoYeBG.mp4" controls preload="auto"></video> -->
                  </div>
                  <div>
                      <!-- 点赞 -->
                      <div class="JiaoHu">
                          <div><img src="../../assets/Public/SanWei/DianZan.png" alt=""></div>
                          <div>点赞</div>
                      </div>
                      <!-- 收藏 -->
                      <div class="JiaoHu">
                          <div><img src="../../assets/Public/SanWei/ShouChang.png" alt=""></div>
                          <div>收藏</div>
                      </div>
                      <!-- 分享 -->
                      <div class="JiaoHu">
                          <div><img src="../../assets/Public/SanWei/FenXiang.png" alt=""></div>
                          <div>分享</div>
                      </div>
                  </div>
              </div>
              <!-- 视频介绍 -->
              <div class="VideoIntroduce">
                  <!-- 标题 -->
                  <div class="VideoIntroduceTitle">靖港古镇三维视频靖港古镇三维视频</div>
                  <!-- 内容 -->
                  <div class="VideoIntroduceCon" style="color:#7B7B7B;">
                      <div class="FaBuTime">2021年12月23发布</div>
                      <div class="ZuoPingLaiYuan">作品来源：</div>
                      <div class="NeiRongJianJie">内容简介：故宫一般指北京故宫。 北京故
                                                  宫是中国明清两代的皇家宫殿，旧称紫禁
                                                  城，位于北京中轴线的中心。北京故宫以
                                                  三大殿为中心，占地面积约72万平方米，
                                                  建筑面积约15万平方米，有大小宫殿七十
                                                  多座，房屋九千余间。北京故宫于明成祖
                                                  永乐四年（1406年）开始建设...故宫一般
                                                  指北京故宫。 北京故宫是中国明清两代的
                                                  皇家宫殿，旧称紫禁城，位于北京中轴线
                                                  的中心。北京故宫以三大殿为中心.......
                                                  心，占地面积约72万平方米，
                                                  建筑面积约15万平方米，有大小宫殿七十
                                                  多座，房屋九千余间。北京故宫于明成祖
                                                  永乐四年（1406年）开始建设...故宫一般
                                                  指北京故宫。 北京故宫是中国明清两代的
                                                  皇家宫殿，旧称紫禁城，位于北京中轴线
                                                  永乐四年（1406年）开始建设
                                                  永乐四年（1406年）开始建设
                                                  永乐四年（1406年）开始建设
                                                  积约72万平方米，
                                                  建筑面积约15万平方米，有大小宫殿七十
                                                  多座，房屋九千余间。北京故宫于明成祖
                                                  永乐四年（1406年）开始建设...故宫一般
                                                  指北京故宫。 北京故宫是中国明清两代的
                                                  皇家宫殿，旧称紫禁城，位于北京中轴线
                                                  永乐四年（1406年）开始建设
                                                  永乐四年（1406年）开始建设
                                                  积约72万平方米，
                                                  建筑面积约15万平方米，有大小宫殿七十
                                                  多座，房屋九千余间。北京故宫于明成祖
                                                  永乐四年（1406年）开始建设...故宫一般
                                                  指北京故宫。 北京故宫是中国明清两代的
                                                  皇家宫殿，旧称紫禁城，位于北京中轴线
                                                  永乐四年（1406年）开始建设
                                                  永乐四年（1406年）开始建设
                                                  积约72万平方米，
                                                  建筑面积约15万平方米，有大小宫殿七十
                                                  多座，房屋九千余间。北京故宫于明成祖
                                                  永乐四年（1406年）开始建设...故宫一般
                                                  指北京故宫。 北京故宫是中国明清两代的
                                                  皇家宫殿，旧称紫禁城，位于北京中轴线
                                                  永乐四年（1406年）开始建设
                                                  永乐四年（1406年）开始建设
                      </div>
                  </div>
              </div>
          </div>
          <!-- 图片列表 -->
          <div class="ImgListBox">
              <div class="TiShi">Shift+鼠标滚轮可横向滑动</div>
              <div class="ImgList">
                  <div id="List">
                      <div class="ImgBox"></div>
                      <div class="ImgTitle">靖港古镇</div>
                  </div>
                  <div id="List">
                      <div class="ImgBox"></div>
                      <div class="ImgTitle">靖港古镇</div>
                  </div>
                  <div id="List">
                      <div class="ImgBox"></div>
                      <div class="ImgTitle">靖港古镇</div>
                  </div>
                  <div id="List">
                      <div class="ImgBox"></div>
                      <div class="ImgTitle">靖港古镇</div>
                  </div>
                  <div id="List">
                      <div class="ImgBox"></div>
                      <div class="ImgTitle">靖港古镇</div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data() {
      return {
        
      };
    },
    mounted() {
      
    },
    created() {
      
    },
    methods: {
      
    }
  }
  </script>
  
  <style lang="less" scoped>
  .BigBox{
      width: 100%;
      height: 100%;
      position: relative;
      overflow-y: auto;
      .Nanber{
          width: 100%;
          height: 300px;
          background-image: url(../../assets/QingXie/QingXieNanber.png);
          background-size: 100% 100%;
          display: flex;
          flex-direction: column-reverse;
          .More{
              width: 80%;
              height: 80px;
              margin-left: 50px;
              color: white;
              display: flex;
              .PlaceName{
                  width: 200px;
                  height: 60px;
                  overflow: hidden;
                  background-image: url(../../assets/QingXie/PlaceNameBG.png);
                  background-size: 100% 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 28px;
                  letter-spacing:5px
              }
              .EvenMore{
                  width: 85px;
                  height: 65px;
                  margin-left: 30px;
                  overflow: hidden;
                  background-image: url(../../assets/QingXie/EvenMore.png);
                  background-size: 100% 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  letter-spacing:5px;
                  font-size: 15px;
                  cursor: pointer;
              }
          }
      }
      .ContentsBox{
          width: 100%;
          height: 950px;
          .Crumbs{
              width: 230px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 20px;
              margin: 30px 150px;
              img{
                  height: 15px;
              }
              div{
                  // 文字不换行
                  word-break:break-all;//英文
                  white-space:pre-wrap;//中文
              }
          }
          .VideoConBox{
              width: 75%;
              height: 52%;
              display: flex;
              justify-content: space-between;
              margin: 0 auto;
              border: 1px solid red;
              .VideoBox{
                  width: 70%;
                  height: 100%;
                  .div{
                      width: 100%;
                      height: 88%;
                      background-color: rgb(0, 0, 0);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      video{
                          height: 100%;
                          width: 100%;
                      }
                  }
                  div{
                      width: 100%;
                      height: 12%;
                      background-color: rgb(104,104,104);
                      display: flex;
                      align-items: center;
                      .JiaoHu{
                          width: 80px;
                          margin: 0 40px;
                          display: flex;
                          justify-content:space-between;
                          color: white;
                          font-size: 2vh;
                          word-break:break-all;//英文
                          white-space:pre-wrap;//中文
                          img{
                              height: 25px;
                              cursor: pointer;
                          }
                      }
                  }
              }
              .VideoIntroduce{
                  width: 28%;
                  height: 100%;
                  overflow: scroll;
              }
              .VideoIntroduceTitle{
                  font-size: 2.4vh;
              }
              .VideoIntroduceCon{
                  font-size: 2vh;
                  width: 100%;
                  .FaBuTime{
                      margin-top:10px;
                  }
                  .ZuoPingLaiYuan{
                      margin: 10px 0;
                      display: flex;
                      flex-wrap:wrap
                  }
                  .NeiRongJianJie{
                      margin: 10px 0;
                      display: flex;
                      flex-wrap:wrap
                  }
              }
          }
          .ImgListBox{
              width: 75%;
              height: 35%;
              margin: 25px auto;
              background-color: #DDDDDD;
              border: 1px solid #DDDDDD;
              .TiShi{
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  margin: 10px 0;
              }
              .ImgList{
                  width: 100%;
                  height: 80%;
                  overflow-x: auto;
                  overflow-y: hidden;
                  display: flex;
                  #List{
                      width: 300px;
                      height: 100%;
                      background-color: aqua;
                      margin: 0 10px;
                      flex-shrink: 0;
                      .ImgBox{
                          width: 100%;
                          height: 80%;
                          border: 1px solid red;
                      }
                      .ImgTitle{
                          width: 100%;
                          height: 20%;
                          background-color: rgba(93, 93, 93, 1);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                      }
                  }
              }
          }
      }
      // 滚动条设置
    @remvw: 1920 /100vw;
  
  ::-webkit-scrollbar {
    width: 7 / @remvw;
    /*对垂直流动条有效*/
    height: 7 / @remvw;
    /*对水平流动条有效*/
  }
  
  /*定义外层滚动槽*/
  ::-webkit-scrollbar-track-piece {
    border-radius: 6 / @remvw;
  }
  
  /*定义内层 滑块颜色、内阴影及圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 6 / @remvw;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #8b8b8b;
    opacity: 0.3;
  }
  
  /*定义两端按钮的样式*/
  ::-webkit-scrollbar-button {
    display: none;
  }
  
  /*定义边角样式*/
  ::-webkit-scrollbar-corner {
    display: none;
  }
  }
  
  </style>